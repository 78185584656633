import Swiper, { Pagination } from 'swiper';
import 'swiper/css';

window.addEventListener('load', (e) => {
	const storiesSwiper = document.querySelector('.stories-swiper');

	if (storiesSwiper) {
		const storiesSwipers = new Swiper(storiesSwiper, {
			modules: [Pagination],
			spaceBetween: 0,
			slidesPerView: 1,
			speed: 600,
			pagination: {
				el: storiesSwiper.querySelector('.swiper-pagination'),
				clickable: true,
			},
			breakpoints: {
				600: {
					spaceBetween: 30,
				},
				1100: {
					spaceBetween: 60,
				},
			},
		});
	}
});
